@tailwind base;
@tailwind components;
@tailwind utilities;

/* Override Swiper navigation arrow styles */
.swiper-button-prev, .swiper-button-next{
    color: white !important; /* Change arrow color to white */
    font-size: 24px !important;
}

.swiper-button-prev:after, .swiper-button-next:after {
    font-size: 24px !important;
}

/* Override Swiper pagination bullet styles */
.swiper-pagination-bullet-active {
    background-color: white !important; /* Change active pagination bullet color to white */
}

.white-logo {
    filter: brightness(0) invert(1);
}