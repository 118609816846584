@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Override Swiper navigation arrow styles */
.swiper-button-next,
.swiper-button-prev {
    color: white; /* Change arrow color to white */
}

/* Override Swiper pagination bullet styles */
.swiper-pagination-bullet-active {
    background-color: white; /* Change active pagination bullet color to white */
}

/* You can also customize other Swiper styles as needed */
